
const INITIAL_REQUEST = {
    id:null,
    sellerId: '',
    sellerName:  '',
    customerName: '',
    customerNumber: '',
    delivery:{
        area:'',
        zone:''
    },
    additionalAddressDetail: '',
    collectCash: false,
    totalAmount: '0',
    specialNotes: '',
    customPickup: false,
    pickupName:'',
    pickupNumber: '',
    pickupNotes: '',
    pickupLocation: {
        latitude: 29.3759,
        longitude: 47.9774 ,
        fullAddress:[]
    },
    deliveryLocation: {
        latitude: 29.3759,
        longitude: 47.9774,
        fullAddress:[]
    }
};
export default INITIAL_REQUEST