import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import * as firebase from "firebase";

import {
  Button,
  Checkbox,
  Form,
  FormField,
  FormInput,
  Grid,
  GridRow,
  Input,
  Modal,
  Segment,
  TextArea,
} from "semantic-ui-react";
import GoogleMapPicker from "../GoogleMapPicker";

import INITIAL_REQUEST from "../../../constants/driverRequest";
import KwFinder from "../../../components/KwFinder";

const INITIAL_ERRORS = {
  customerNumber: "",
  customerName: "",
  deliveryLocation: "",
  pickupLocation: "",
};

const InitialFormState = {
  touched: {
    customerNumber: false,
    customerName: false,
    deliveryLocation: false,
    pickupLocation: false,
  },
  errors: { ...INITIAL_ERRORS },
  isFormValid: false,
};

const RequestForm = ({
  open,
  onCancel,
  onSubmit,
  sendButtonDisabled,
  initialValue,
}) => {
  const { t } = useTranslation("requestDriver");
  const currentUser = firebase.auth().currentUser;
  const [showDeliveryLocation, setShowDeliveryLocation] = useState(false);
  const [refreshKWfinderValue, setRefreshKWfinderValue] = useState({
    pickupLocation: new Date(),
    deliveryLocation: new Date(),
  });

  const [formValue, setFormValue] = useState(() => {
    return initialValue
      ? { ...initialValue }
      : {
          ...INITIAL_REQUEST,
          sellerId: currentUser ? currentUser.uid : "",
          sellerName: currentUser ? currentUser.displayName : "",
        };
  });
  const [{ errors, isFormValid, touched }, setFormState] = useState({
    ...InitialFormState,
  });

  useEffect(() => {
    validateForm();
  }, [formValue]);

  useEffect(() => {
    setFormState({ ...InitialFormState });
    setFormValue((prevFormValue) => ({
      ...prevFormValue,
      ...initialValue,
      sellerId: currentUser ? currentUser.uid : prevFormValue.sellerId,
      sellerName: currentUser
        ? currentUser.displayName
        : prevFormValue.sellerName,
    }));
  }, [initialValue, currentUser]);

  const inputFields = [
    {
      label: t("requestDriver:CustomerName"),
      name: "customerName",
      value: formValue.customerName,
      required: true,
      width: "60%",
    },
    {
      label: t("requestDriver:CustomerNumber"),
      name: "customerNumber",
      value: formValue.customerNumber,
      required: true,
      width: "35%",
    },
  ];

  const handleInputChange = (e, { name, value }) => {
    setFormValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setTouched(name);
  };

  const handleToggleChange = () => {
    const newValue = { ...formValue, collectCash: !formValue.collectCash };
    setFormValue(newValue);
  };

  const setTouched = (name) => {
    setFormState((prevState) => ({
      ...prevState,
      touched: {
        ...prevState.touched,
        [name]: true,
      },
    }));
  };

  const validateForm = () => {
    // Initialize error object and validity flag
    let errors = { ...INITIAL_ERRORS };

    let isValid = true;

    // Validate customerNumber
    if (!formValue.customerNumber) {
      errors.customerNumber = "Customer number is required";
      isValid = false;
    } else if (formValue.customerNumber.length !== 8) {
      // Fix: Changed from 8 digits check
      errors.customerNumber = "Customer number must be exactly 8 digits";
      isValid = false;
    } else if (!/^\d+$/.test(formValue.customerNumber)) {
      errors.customerNumber = "Customer number must contain only digits";
      isValid = false;
    }

    // Validate customerName
    if (!formValue.customerName) {
      errors.customerName = "Customer name is required";
      isValid = false;
    }

    if (
      !formValue.deliveryLocation.longitude ||
      !formValue.deliveryLocation.latitude
    ) {
      errors.deliveryLocation = "The delivery location must be selected";
      isValid = false;
    }

    if (formValue.deliveryLocation.fullAddress.length < 2) {
      errors.deliveryLocation = "Select complete delivery location.";
      isValid = false;
    }

    if (
      !formValue.pickupLocation.longitude ||
      !formValue.pickupLocation.latitude
    ) {
      errors.pickupLocation = "Select complete pickup location";
      isValid = false;
    }

    // Update the state with errors and delivery
    setFormState((prevState) => ({
      ...prevState,
      isFormValid: isValid,
      errors, // Update the errors in state
    }));

    return isValid;
  };

  const onKwfinderValueChange = (val, key) => {
    const newDeliveryLocation = {
      [key]: {
        latitude: val.latitude,
        longitude: val.longitude,
        fullAddress: val.fullAddress,
      },
    };
    setFormValue((prevState) => ({
      ...prevState,
      ...newDeliveryLocation,
    }));
    setTouched(key);
  };

  useEffect(() => {
    validateForm();
  }, [touched]);

  function handlePickuplocationInputChange(value, key) {
    const newVal = { ...formValue };
    newVal.pickupLocation = {
      ...newVal.pickupLocation,
      fullAddress: [],
      [key]: value,
    };
    setRefreshKWfinderValue((prevState) => {
      return {
        ...prevState,
        pickupLocation: new Date(),
      };
    });
    setFormValue(newVal);
  }

  return (
    <Modal
      open={open}
      style={{ overflow: "hidden", width: "50%", background: "#a9a9a903" }}
      className={"request-driver-form"}
      size="large"
    >
      <Modal.Header>{t("requestDriver:RequestADriver")}</Modal.Header>
      <Modal.Content style={{ overflowY: "auto" }}>
        <Form>
          <div>
            {/*pickup Information*/}
            <section>
              <Checkbox
                style={{ marginLeft: 10, marginTop: 10, marginBottom: 10 }}
                label="Turn on to change the pickup location. By default it will use your store location"
                toggle
                checked={formValue.customPickup}
                name="customPickup"
                onChange={(e) => {
                  if (!formValue.customPickup) {
                    if (
                      // eslint-disable-next-line no-restricted-globals
                      confirm(
                        // eslint-disable-next-line no-restricted-globals
                        "Are you sure you want to select the different pickup location ?",
                      )
                    ) {
                      handleInputChange(e, {
                        name: "customPickup",
                        value: true,
                      });
                      return;
                    }
                    return;
                  }
                  handleInputChange(e, {
                    name: "customPickup",
                    value: !formValue.customPickup,
                  });
                }}
              />

              {formValue.customPickup && (
                <div>
                  <h3>Pickup Location</h3>
                  <GoogleMapPicker
                    googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAhnDqtNZ54BV1nQJz0eK3hwhDsb_yMnZg`}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `300px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    initialLocation={formValue.pickupLocation}
                  />
                  <Segment>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column>
                          <h3>Pickup Location</h3>
                          {errors?.pickupLocation}
                          {!!errors["pickupLocation"] &&
                            touched.pickupLocation && (
                              <p style={{ color: "red" }}>
                                {errors["pickupLocation"]}
                              </p>
                            )}
                          <KwFinder
                            placeHolder={"Select pickup location"}
                            clearFilter={refreshKWfinderValue.pickupLocation}
                            onLocationSet={(val) => {
                              onKwfinderValueChange(val, "pickupLocation");
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Grid>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <FormField>
                            <Input
                              type="number"
                              label="Latitude:"
                              name="latitude"
                              fluid
                              value={formValue.pickupLocation.latitude}
                              onChange={(e) => {
                                handlePickuplocationInputChange(
                                  1 * e.target.value,
                                  "latitude",
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </FormField>
                        </Grid.Column>

                        <Grid.Column>
                          <FormField>
                            <Input
                              type="number"
                              id="longitude"
                              label="Longitude:"
                              fluid
                              value={formValue.pickupLocation.longitude}
                              onChange={(e) => {
                                handlePickuplocationInputChange(
                                  1 * e.target.value,
                                  "longitude",
                                );
                              }}
                              style={{ width: "100%" }}
                            />
                          </FormField>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={2}>
                        <Grid.Column>
                          <FormField>
                            <Input
                              label="Pickup Name"
                              id="pickupName"
                              name="pickupName"
                              value={formValue.pickupName}
                              onChange={(e) =>
                                handleInputChange(e, {
                                  name: "pickupName",
                                  value: e.target.value,
                                })
                              }
                              placeholder="Enter Pickup Name"
                            />
                          </FormField>
                        </Grid.Column>
                        <Grid.Column>
                          <FormField>
                            <Input
                              label="Pickup Number"
                              id="pickupNumber"
                              name="pickupNumber"
                              value={formValue.pickupNumber}
                              onChange={(e) =>
                                handleInputChange(e, {
                                  name: "pickupNumber",
                                  value: e.target.value,
                                })
                              }
                              placeholder="Enter Pickup Number"
                            />
                          </FormField>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row columns={1}>
                        <Grid.Column>
                          <Form.Input
                            label="Pickup Notes"
                            id="pickupNotes"
                            name="pickupNotes"
                            control={TextArea}
                            value={formValue.pickupNotes}
                            onChange={(e) =>
                              handleInputChange(e, {
                                name: "pickupNotes",
                                value: e.target.value,
                              })
                            }
                            placeholder="Enter Pickup Notes"
                            style={{ minHeight: 10 }} // Adjust height as needed
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                </div>
              )}
            </section>

            {/*customer information*/}
            <Segment>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {inputFields.map((field, index) => (
                  <div key={index} style={{ width: field.width }}>
                    <FormInput
                      onBlur={() => setTouched(field.name)}
                      required={field.required}
                      placeholder={field.label} // Ensure you provide the correct translation or placeholder
                      style={{ paddingBottom: 8, width: "100%" }}
                      fluid
                      label={field.label}
                      name={field.name}
                      error={
                        field.required &&
                        touched[field.name] &&
                        !!errors[field.name]
                          ? { content: errors[field.name] }
                          : false
                      }
                      size="large"
                      value={field.value}
                      onChange={handleInputChange}
                    />
                  </div>
                ))}
              </div>
              <div>
                <FormField
                  control={TextArea}
                  label="Special Notes"
                  name={"specialNotes"}
                  placeholder={"Add your special notes"}
                  value={formValue.specialNotes}
                  onChange={handleInputChange}
                />
              </div>
            </Segment>
            {/*delivery address*/}
            <Segment>
              <Grid>
                <Grid.Row>
                  <Grid.Column>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <h3>Delivery Location Zone</h3>
                      <div>
                        {!!errors["deliveryLocation"] &&
                          touched.deliveryLocation && (
                            <p style={{ color: "red" }}>
                              {errors["deliveryLocation"]}
                            </p>
                          )}
                      </div>
                    </div>
                    <KwFinder
                      placeHolder={"Select delivery address"}
                      initialValue={formValue.deliveryLocation.fullAddress}
                      clearFilter={
                        refreshKWfinderValue.deliveryLocation || initialValue
                      }
                      onLocationSet={(val) => {
                        onKwfinderValueChange(val, "deliveryLocation");
                      }}
                    />
                  </Grid.Column>
                </Grid.Row>
                <div style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      textAlign: "center",
                      marginLeft: 10,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Checkbox
                      label="Turn on to view map of delivery location based on above address."
                      toggle
                      checked={showDeliveryLocation}
                      name="customPickup"
                      onChange={(e) => {
                        setShowDeliveryLocation(!showDeliveryLocation);
                      }}
                    />
                    <div>
                      <span>
                        <strong>Latitude:</strong>&nbsp;
                        {formValue.deliveryLocation.latitude}
                      </span>
                      &nbsp;&nbsp;
                      <span>
                        <strong>Longitude:</strong>&nbsp;
                        {formValue.deliveryLocation.longitude}
                      </span>
                    </div>
                  </div>

                  {showDeliveryLocation && (
                    <GoogleMapPicker
                      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAhnDqtNZ54BV1nQJz0eK3hwhDsb_yMnZg`}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `300px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      initialLocation={formValue.deliveryLocation}
                    />
                  )}
                </div>
                <GridRow columns={1} style={{ padding: 0 }}>
                  <Grid.Column>
                    <FormInput
                      onBlur={validateForm}
                      style={{ marginBottom: 10, marginTop: 10, width: "100%" }}
                      label={t("requestDriver:AdditionalAddress")}
                      name={"additionalAddressDetail"}
                      value={formValue.additionalAddressDetail}
                      onChange={handleInputChange}
                    />
                  </Grid.Column>
                </GridRow>
              </Grid>
            </Segment>
            <Segment>
              <Checkbox
                label={t("requestDriver:CollectCash")}
                checked={formValue.collectCash}
                onChange={handleToggleChange}
              />
              {formValue.collectCash && (
                <Input
                  style={{ marginBottom: 10, marginLeft: 12 }}
                  label={t("requestDriver:Total")}
                  name="totalAmount"
                  value={formValue.totalAmount}
                  onChange={handleInputChange}
                  type={"number"}
                />
              )}
            </Segment>
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button disabled={sendButtonDisabled} onClick={onCancel} color="red">
          {t("cancel")}
        </Button>
        <Button
          disabled={!isFormValid || sendButtonDisabled}
          onClick={() => onSubmit(formValue)}
          type="submit"
          color="green"
        >
          {t("send")}
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default RequestForm;
