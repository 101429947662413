import gql from "graphql-tag";

export const GET_ORDERS = gql`
  query GetSellerOrders($status: [String], $multipleOutlet: Boolean) {
    getSellerOrders(status: $status, multipleOutlet: $multipleOutlet) {
      _id
      timeStamp
      timeStampEta
      storeId
      storeLogo
      storeName
      customerId
      total
      additionalPricingInfo
      orderNumber
      orderStatus
      orderNotes
      paymentMethod
      orderRating
      customerDeliveryCharge
      promoCode
      promoInfo {
        value
        isCashback
      }
      deliveryInfo {
        driverName
        driverMobile
      }
      deliveryOrderID
      items {
        _id
        name
        quantity
        price
        category
        productId
        variantId
        options {
          category
          optionsList {
            name
            price
          }
        }
        oldQuantity
        available
      }
      itemsCoupons {
        _id
        name
        description
        price
        quantity
      }
      orderType
      userAddress{
        addressType,
        area,
        block,
        street,
        building,
        floor,
        apartmentNo,
        houseNo,
        office,
        landmark,
        label,
        location{
          coordinates
        }
      }
      vendor
      deliveryVendor
      userInfo {
        uid
        name
        phoneNumber
      }
      availableVendors
      walletdec
      groceryOnly
      preferredDeliverySlot
      asapDelivery
    }
  }
`;

export const FIND_SELLERS_AND_PRODUCTS = gql`
  query FindWhere($searchString: String!) {
    findWhere(searchString: $searchString)
  }
`;

export const GET_USER = gql`
  query GetUserInfoFireBase($userId: String) {
    getUserInfoFireBase(userId: $userId) {
      uid
      name
      phoneNumber
    }
  }
`;

export const GET_STORE = gql`
  query GetStore {
    getStore {
      _id,
      isOpen,
      busyMode,
      busyModeExpiry,
      shopName,
      address,
      deliveryVendors,
      services,
      disabledServices,
      mainOutlet,
      linkedOutlets,
      linkedOutletsName,
      location {
        latitude
        longitude
      }
      __typename
    }
  }
`;

export const GET_SERVICES = gql`
  query GetServices {
    getServices {
      name,
      _id,
    }
  }
`;

export const GET_ORDERS_WITH_PAGINATION = gql`
  query GetSellerOrdersWithPagination(
    $status: [String]
    $date: String
    $options: PaginationOptions!
  ) {
    getSellerOrdersWithPagination(
      status: $status
      date: $date
      options: $options
    ) {
      totalDocs
      limit
      totalPages
      page
      pagingCounter
      prevPage
      nextPage
      hasPrevPage
      hasNextPage
      docs {
        _id
        timeStamp
        timeStampEta
        orderNumber
        orderStatus
        paymentMethod
        orderRating
        total
      }
    }
  }
`;

export const GET_ORDERS_TIMESTAMP = gql`
  query getSellerOrderDates($status: [String], $dateFilter: DateFilterOptions) {
    getSellerOrderDates(status: $status, dateFilter: $dateFilter) {
      _id
    }
  }
`;

export const GET_ORDER_BY_ID = gql`
  query getOrderByID($orderID: String!) {
    getOrderByID(orderID: $orderID) {
      _id
      timeStamp
      timeStampEta
      storeId
      storeLogo
      storeName
      customerId
      total
      additionalPricingInfo
      orderNumber
      orderStatus
      orderNotes
      paymentMethod
      deliveryVendor
      orderRating
      vendor
      walletdec
      promoCode
      promoInfo {
        value
        isCashback
      }
      items {
        _id
        name
        quantity
        price
        options {
          category
          optionsList {
            name
            price
          }
        }
      }
      itemsCoupons {
        _id
        name
        description
        price
        quantity
      }
      customerDeliveryCharge
      deliveryInfo {
        driverName
        driverMobile
      }
      deliveryOrderID
      items {
        _id
        name
        quantity
        price
        options {
          category
          optionsList {
            name
            price
          }
        }
      }
      orderType
      userAddress{
        addressType,
        area,
        block,
        street,
        building,
        floor,
        apartmentNo,
        houseNo,
        office,
        landmark,
        label,
        location{
          coordinates
        }
      }
      vendor
      userInfo {
        uid
        name
        phoneNumber
      }
    }
  }
`;

export const GET_STORE_MENU = gql`
  query GetStore($hideUnavailableProducts: Boolean ,  $id: String) {
    getStore(hideUnavailableProducts:$hideUnavailableProducts ,id: $id) {
      _id,
      shopName,
      linkedOutletsName,
      linkedOutlets,
      mainOutlet
      products {
        _id
        products {
          name
          price
          image
          _id
          tags
          category
          description
          options {
            _id
            category
            maxSelect
            minSelect
            optionsList {
              name
              price
            }
          }
          available
          productId
        }
      }
    }
  }
`;

export const SEARCH_STORE_PRODUCT = gql`
  query searchStoreProduct($storeId: String, $searchText: String) {
    searchStoreProduct(storeId: $storeId, searchText: $searchText) {
          _id
          name
          price
          category
          subCategory
          tags
          originalPrice
          available
          image
          productId
          description
          options {
            _id
            category
            maxSelect
            minSelect
            optionsList {
              _id
              name
              price
            }
          }
    }
  }
`;

export const GET_APP_PROPERTIES = gql`
  query getAppProperties {
    getAppProperties {
      mashkorPhoneNumber
    }
  }
`;

export const GET_STORE_STATS = gql`
  query GetRestaurantStats($input: RestaurantStatsInput) {
    getRestaurantStats(input: $input) {
      success
      message
      fieldErrors {
        field
        message
      }
      data  {
        totalNumOfOrder
        stats {
          status
          count
        }
        salesStat
        orderTypeStats
      }
    }
  }
`;

export const GET_VENDOR = gql`
  query getVendor($uid: String) {
    getVendor(uid: $uid) {
      name
      phone
      uniqueId
      manager
      _id
    }
  }
`

export const GET_KMART_ORDERITEM = gql`
  query getOrderItems($vendorId: String, $pending: Boolean) {
    getOrderItems(vendorId: $vendorId, pending: $pending) {
      name
      price
      quantity
      total
      orderId
      status
      _id
      createdAt
      notes
      options {
        label
        price
      }
      order {
        customerName
        customerId
        status
        customerDeliveryAddress
        paymentMethod
      }
    }
  }
`


export const GET_VENDOR_ITEMS = gql`
query getProducts($pagination: Pagination, $filter: JSON) {
  getProducts(pagination: $pagination, filter: $filter) {
    name
    price
    description
    categories
    stock
    _id
    
  }
}
`;

export const GET_ALL_TAGS = gql`
  query getAllProductTags{
    getAllProductTags{
      name
      id
    }
  }
`;

export const GET_ALL_SELLER_CATEGORIES = gql`
  query getProductCategoriesBySeller {
    getProductCategoriesBySeller
  }
`;

export const GET_BULK_UPDATES = gql`
query getBulkUpdatesBySeller {
  getBulkUpdatesBySeller {
    status
    updatedAt
    products {
      _id
      sellerId
      itemId
      name
      price
      category
      description
      tags
      image
      options {
        _id
        category
        maxSelect
        minSelect
        optionsList {
          _id
          name
          price
        }
      }
    }
  }
}
`
export const GET_ITEM_EDITS = gql`
  query getPendingItemsBySeller {
    getPendingItemsBySeller {
      _id
      sellerId
      itemId
      name
      price
      category
      description
      tags
      image
      options {
        _id
        category
        maxSelect
        minSelect
        optionsList {
          _id
          name
          price
        }
      }
      approved
      createdAt
      updatedAt
    }
  }
`;

export const GET_DRIVER_REQUESTS = gql`
  query getTasks($filter: JSON, $page: Int, $limit: Int, $sort: String, $sortJson: JSON) {
    getTasks(filter: $filter, page: $page, limit: $limit, sort: $sort, sortJson: $sortJson) {
      total
      limit
      tasks {
        id
        orderNumber
        customerName
        customerNumber
        deliveryZone
        additionalAddressDetail
        collectCash
        totalAmount
        specialNotes
        status
        driver {
          name
          phone
          trackingLink
        }
        statusHistory {
          status
          timestamp
        }
        completedTime
        deliveryTime
        customPickup
        pickupLocation {
          latitude
          longitude
          fullAddress
        }
        deliveryLocation {
          latitude
          longitude
          fullAddress
        }
        addressDetails {
          fromAddr {
            address
            moreInfo {
              streetNumber
              streetName
              neighborhood
              locality
              administrativeArea
              country
              postalCode
            }
          }
          toAddr {
            address
            moreInfo {
              streetNumber
              streetName
              neighborhood
              locality
              administrativeArea
              country
              postalCode
            }
          }
        }
        travelDetail {
          totalDistance
          duration
          durationInTraffic
          hasTraffic
          estimatedDelayByTraffic
        }
        createdAt
        tookanwebhookrequest
      }
    }
  }
`;


export const GET_A_TASK = gql`
  query getTask($id: ID!) {
    getTask(id: $id){
        id
        orderNumber
        customerName
        customerNumber
        deliveryZone
        additionalAddressDetail
        collectCash
        totalAmount
        specialNotes
        status
        driver {
          name
          phone
          trackingLink
        }
        statusHistory {
          status
          timestamp
        }
        completedTime
        deliveryTime
    }
  }
`;

export const FIND_ADDRESSES_QUERY = gql`
  query findAddresses($filters: KAddressInput, $page: Int, $limit: Int, $sort: String) {
    findAddresses(filters: $filters, page: $page, limit: $limit, sort: $sort) {
      addresses {
        _id
        block
        block_ar
        governate
        governate_ar
        house
        house_ar
        level
        neighborhood
        neighborhood_ar
        parcelarabic
        parcelenglish
        street
        street_ar
        civilid
        unittype
        unittype_ar
        featuretype
        createdAt
        updatedAt
        location {
          type
          coordinates
        }
      }
      page
      limit
      totalPages
      total
    }
  }
`;

export const IS_IN_COLLECTION = gql`
  query checkStoreInCollection($storeId: String){
    checkStoreInCollection(storeId: $storeId)
  }
`;