import {
    gql
} from '@apollo/client';

const typeDefs = gql `
  extend type Sort {
    sortBy:String
    order:Int
  }

  extend type PaginationOptions {
    sort: Sort!, 
    page:Int, 
    limit:Int, 
    select:String
  }

  extend type DateFilterOptions {
    from:String!,
    to:String!
  }

  extend input TaskInput {
    sellerId: ID!
    sellerName: String
    orderNumber: String
    customerName: String
    customerNumber: String!
    deliveryZone: String!
    additionalAddressDetail: String
    collectCash: Boolean
    totalAmount: String
    specialNotes: String
  }
`;

export default typeDefs;